.flanProdList {
    #BodyWrap .ContentWrapper {
        max-width: 100% !important;
        padding: 0 !important;
    }

    .FilterProductsTable {
        display: flex;
    }

    .s-maincontent-container {
        max-width: var(--max-container-width);
        margin-left: auto;
        margin-right: auto;

        .pagination-bottom {
            padding: 20px;

            @media (min-width: 1022px) {
                padding-left: 32px;
                padding-right: 32px;
            }

            .pagination {
                margin: 0;

                @media (min-width: 1022px) {
                    width: 80%;
                    float: right;
                }

                #divPagination .disablePaginationArrow {
                    display: inline-block;
                    pointer-events: none;
                    opacity: 0.3;
                }
            }
        }
    }

    #FiltersAndProductsWrapper {
        padding: 0 20px;

        @media (min-width: 1022px) {
            padding: 0 32px;
        }
    }

    .s-productscontainer2:not(.swiper-wrapper) {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 16px;
        margin: 0;
    }

    .s-productscontainer2 > li {
        padding: 0;
        margin: 0;
        width: 100%;

        &:hover {
            .hotspotquickbuy {
                visibility: visible;
            }

            .reviews-container {
                visibility: visible;
            }
        }
    }

    .s-productscontainer2 > li:nth-child(n + 9) {
        content-visibility: auto;
        contain-intrinsic-size: auto 350px;
    }

    &.has-member-pricing-product-on-plp {
        .plp-advert-placement {
            order: 3 !important;
        }

        &.has-1-sponsored-products {
            .plp-advert-placement {
                order: 2 !important;
            }
        }

        &.has-2-sponsored-products {
            .plp-advert-placement {
                order: 1 !important;
            }
        }

        &.has-3-sponsored-products {
            .plp-advert-placement {
                order: 0 !important;
            }
        }

        &.has-4-sponsored-products {
            .plp-advert-placement {
                order: 3 !important;
            }
        }
    }

    .s-productthumbbox {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .s-productthumbimage {
            height: 100%;
            width: 100%;
            padding: 0;
            display: flex;
            flex-direction: column;

            .ProductImageList {
                margin-bottom: 0;
                width: auto;
                height: auto;
                flex: 0 0 auto;
                padding: 10px 10px 0;
                align-items: center;
                justify-content: center;
                display: flex;

                @media (max-width: 1021px) {
                    padding: 0;
                }

                img {
                    aspect-ratio: 1/1;
                    min-height: auto;
                }

                > div:first-of-type {
                    aspect-ratio: unset;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    &::before {
                        content: none;
                    }
                }

                .AlternateImageContainerDiv {
                    inset: 10px 10px 0;
                    height: auto;
                    width: auto;

                    @media (max-width: 1021px) {
                        inset: 0;
                    }
                }
            }
        }

        .s-product-sache {
            top: 10px;
            left: 10px;
            right: auto;
            z-index: unset !important;
        }

        .hotspotbuy {
            &.hotspotwishlist {
                left: auto;
                right: 10px;
                top: 10px;
            }

            &.hotspotquickbuy {
                display: flex !important;
                position: relative;
                top: auto;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 44px;
                visibility: hidden;
                text-transform: capitalize;
                font-weight: var(--fw-medium);
                justify-content: center;
                align-items: center;

                &::before {
                    background-color: var(--flan-black);
                    content: "";
                    height: 2px;
                    width: calc(100% - 20px);
                    margin: 0 auto;
                    position: absolute;
                    top: 0;
                    left: 10px;
                    right: 10px;
                }

                .QuickLookIcon {
                    display: none;
                }

                .QuickLookText {
                    display: block;
                    font-size: 14px;
                    line-height: 14px;
                    letter-spacing: 0.1em;
                }
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .s-productthumbtext {
            cursor: pointer;
            padding: 20px 10px 0;
            text-align: left;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;

            @media (max-width: 1021px) {
                padding: 20px 0 0;
            }
        }

        .s-producttext-withticket .AdditonalPriceLabel {
            display: none;
        }

        .s-producttext-top-wrapper {
            padding: 0;

            a {
                font-size: 13px;
                letter-spacing: 0.666667px;
                line-height: 18px;
                text-transform: uppercase;

                .productdescriptionbrand {
                    font-weight: var(--fw-bold) !important;
                    margin-bottom: 5px;
                }

                .productdescriptionname {
                    margin-bottom: 10px;
                }
            }
        }

        .s-producttext-price {
            padding: 0 0 10px;

            span {
                font-size: 13px !important;
                line-height: 15px;
                letter-spacing: 0.666667px;
                font-weight: var(--fw-bold);
            }

            &.s-producttext-withticket {
                .curprice {
                    color: var(--flan-red);
                }
            }

            .s-largered,
            .RefandPrice {
                display: inline-block;
            }
        }
    }

    [li-name="Voucher Gift Card"] .ticketPricePrefix,
    [li-name="Voucher Gift Card"] .s-smalltext {
        display: none;
    }

    [li-name="Voucher Gift Card"] .s-productthumbbox .s-producttext-price.s-producttext-withticket .curprice {
        color: #000;
    }

    #navlist > li:hover .s-productthumbbox {
        box-shadow:
            0 1px 10px rgba(0, 0, 0, 0.025),
            0 8px 25px rgba(0, 0, 0, 0.1);
    }

    .pagination span.PageSelector {
        text-shadow: 0 0 1px #000;
    }

    .pagination .PageNumber a.swipeNumberClick {
        border: none;
    }

    .pagination .PageNumber a:hover {
        border-color: #aaa;
    }

    #TopPaginationWrapper .PageFromTo .currentProducts,
    #TopPaginationWrapper .PageFromTo .countDelimeter {
        display: none;
    }

    #ProductContainer {
        position: relative;
        width: 100%;
    }

    .SortQtyName {
        display: none;
        font-size: 1em;
    }

    .ddlSortOptions li.ddlSortOption[data-selected="true"] span,
    .ddlSortOptions li.ddlSortOption:hover span {
        font-weight: var(--fw-semibold);
    }

    #productlistcontainer .reviews-container {
        margin-bottom: 10px !important;
        min-height: 20px;
        display: flex;
        align-items: flex-end;
        justify-content: left;
        flex: 1 1 auto;
    }

    .ddlSortOptionsOpen .ddlSortOptions li span::before {
        display: none;
    }

    .rollUpQuickBuyWrap .product-rollup-list {
        padding-bottom: 16px;
    }

    .rollUpQuickBuyWrap .product-rollup-more {
        padding-bottom: 16px;
        margin-top: -8px;
    }

    .PrevNextButtons {
        line-height: 2.4em;
    }

    .hotspotbuy .QuickLookIcon,
    .hotspotbuy.hotspotquickbuy:hover .QuickLookIcon {
        background-size: 400px 400px;
        background-position: -365px -127px;
    }

    .hotspotbuy.hotspotquickbuy:hover .QuickLookIcon {
        background-position: -365px -161px;
    }

    .hotspotbuy.hotspotwishlist {
        &,
        .wishListSVG,
        .wishListSVG .global-icon-wishlist {
            height: 45px;
            width: 45px;
            padding: 0;
            margin: 0;
        }

        .wishListSVG .global-icon-wishlist {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg {
            width: 30px;
            top: auto;
            transform: none;

            path {
                stroke-width: 1px;
            }
        }

        &:hover svg path {
            stroke-width: 2px;
        }

        &.addedWishList {
            display: block !important;

            path {
                stroke-width: 2px;
                fill: var(--flan-neon);
            }
        }
    }
}

.flanProdList.Browse h1 {
    font-size: 1.5em;
}

@media (max-width: 767px) {
    .flanProdList {
        .s-productscontainer2:not(.swiper-wrapper) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &.has-member-pricing-product-on-plp {
            .plp-advert-placement {
                order: 1 !important;
            }

            &.has-1-sponsored-products,
            &.has-3-sponsored-products {
                .plp-advert-placement {
                    order: 0 !important;
                }
            }

            &.has-2-sponsored-products,
            &.has-4-sponsored-products {
                .plp-advert-placement {
                    order: 1 !important;
                }
            }
        }
    }
}

@media (max-width: 1021px) {
    .flanProdList {
        .s-productthumbbox .s-productthumbtext {
            position: static;
            padding: 20px 0 0;
        }

        .s-productthumbbox .hotspotbuy.hotspotwishlist {
            display: block !important;
            width: 16px;
            height: 15px;
        }

        .s-productthumbbox .hotspotbuy.hotspotquickbuy {
            display: none !important;
        }

        .hotspotbuy .WishIcon,
        .hotspotbuy.hotspotwishlist:hover .WishIcon {
            background-size: 280px 280px;
            background-position: -83px -88px;
            background-color: transparent;
        }

        #navlist > li:hover .s-productthumbbox {
            box-shadow: none;
        }
    }
}

@media (min-width: 1022px) {
    .flanProdList {
        #ProductContainer {
            padding-left: 20px;
            width: 80%;
        }

        .ddlSortOptionsContainer {
            position: static;
        }

        .ddlSortOptions {
            top: 100%;
            right: -1px;
            left: -1px;
            width: auto;
            border: 1px solid #000;
        }

        .s-productthumbbox .hotspotbuy.hotspotquickbuy .QuickLookText {
            transition: background-color 400ms linear 0s;
        }

        .s-productthumbbox .s-productthumbimage .reviews-container {
            visibility: hidden;
        }

        .PageFromTo .pppt span {
            font-size: 1.1em;
            letter-spacing: normal;
        }
    }

    .flanProdList.Browse h1 {
        font-size: 2em;
    }

    .PrevNextButtons.PageNumber .NextLink,
    .PrevNextButtons.PageNumber .PrevLink,
    .PrevNextButtons.PageNumber .CurrentPageNumber,
    .PrevNextButtons.PageNumber .PageSeperator,
    .PrevNextButtons.PageNumber .MaxPageNumber {
        font-size: 1.1em;
    }
}
